import React from 'react'

const FestivalPlus = (props) => {
	const { videos } = props

	const Video = (props) => {
		const { data } = props

		return (
			<div className="video-container w-full p-4 xl:w-1/2">
				<div className="video xl:p-8 xl:bg-white xl:shadow-lg">
					<div
						className="markdown-content"
						dangerouslySetInnerHTML={{ __html: data.html }}
					/>
				</div>
			</div>
		)
	}

	return (
		<section className="p-4 md:p-8 bg-boswell-handwriting md:flex md:items-start md:flex-wrap">
			{ videos.map((video, index) => {
				// check the publish date for the video
				const publishDate = new Date(video.node.frontmatter.publish_date.replace(/-/g, "/")).getTime()
				const currentDate = new Date().getTime()
				if (publishDate > currentDate) {
					return null
				}
				return <Video data={video.node} key={index} />
			})}
		</section>
	)
}

export default FestivalPlus
