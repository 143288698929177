import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import BannerImageWithOverlay from '../../components/Page/BannerImageWithOverlay'
import FestivalPlus from '../../components/Page/FestivalPlus'

const festivalPlus = ({data}) => {

	return (
        <Layout>
			<BannerImageWithOverlay image={data.bannerImage.childImageSharp.gatsbyImageData}>
				<div className={`p-4 md:p-12 bg-bbf-cool-green bg-opacity-95 text-white`}>
					<h2 className={`text-4xl xl:text-5xl mb-6`}>Festival Plus+</h2>
					<h3 className={`text-2xl xl:text-3xl`}>Festival Fringe Events</h3>
				</div>
			</BannerImageWithOverlay>
			<FestivalPlus videos={data.content.edges} />
		</Layout>
    );
}

export const query = graphql`{
  bannerImage: file(relativePath: {eq: "general/Bozzy-16-9-001.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
  content: allMarkdownRemark(
    filter: {frontmatter: {category: {eq: "festival-plus"}}}
    sort: {fields: frontmatter___page_order, order: ASC}
  ) {
    edges {
      node {
        html
        frontmatter {
          publish_date
        }
      }
    }
  }
}
`

export default festivalPlus
